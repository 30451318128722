import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/about-us',
    name: 'About',
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
  {
    path: '/tools',
    name: 'Tools',
    component: () => import(/* webpackChunkName: "tools" */ '../views/ArtlessTools.vue')
  },
  {
    path: '/ERC1155Tools',
    name: 'Token Transfer',
    component: () => import(/* webpackChunkName: "ERC1155Tools" */ '../views/ERC1155Tools.vue')
  },
  {
    path: '/DaltonScript',
    name: 'Token Transfer',
    component: () => import(/* webpackChunkName: "DaltonScript" */ '../views/DaltonScript.vue')
  }
]

const router = new VueRouter({
  routes,
  mode:"history",
})

export default router
