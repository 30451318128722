<template>
  <v-footer
    padless
    fixed
    v-bind="$attrs"
  >
    <v-card
      flat
      tile
      width="100%"
      class="primary lighten-2 white--text text-center"
    >
      <v-card-text>
        <v-btn
          v-for="(link, idx) in links"
          :key="idx"
          class="mx-4 white--text"
          icon
          target="_blank"
          :href="link.href"
        >
          <v-icon
            size="32px"
          >
            {{ link.icon }}
          </v-icon>
        </v-btn>
      </v-card-text>

      <v-divider />

      <v-card-text class="white--text">
        {{ new Date().getFullYear() }} — <strong>ArtlessLabs</strong>
      </v-card-text>
    </v-card>
  </v-footer>
</template>

<script>
export default {
  name: "PageFooter",
  data() {
    return {
      links:[
        {
          icon:'mdi-discord',
          href:'https://discord.gg/JEAvhNDmnn'
        },
        {
          icon:'mdi-twitter',
          href:'https://twitter.com/ArtlessLabs'
        },
        {
          icon:'mdi-instagram',
          href:'https://www.instagram.com/artlesslabs/'
        }
      ]
    }
  },
}
</script>

<style scoped>

</style>