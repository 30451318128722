<template>
  <v-card
    class="pa-12-md d-md-flex d-sm-block"
  >
    <v-col>
      <v-card-title
        class="text-h4"
        v-text="cardData.title"
      />
      <v-card-subtitle
        class="text-justify mt-5 text--lighten-5 text-h6 font-weight-light"
        v-text="cardData.description"
      />
    </v-col>
    <v-col
      cols="12"
      md="8"
    >
      <v-hover
        v-for="(card,idx) in projects"
        v-slot="{ hover }"
        :key="idx"
      >
        <v-card
          :elevation="hover ? 8 : undefined "
          dark
          :color="card.color"
          class="d-block fp-cards transition-fast-in-fast-out d-md-flex flex-nowrap justify-space-between"
        >
          <v-img
            class="hidden-md-and-up"
            :src="card.imageUrl"
          />
          <div>
            <v-card-title
              class="text-h5"
              v-text="card.text"
            />
            <v-card-subtitle
              class="text-justify"
              v-text="card.description"
            />
            <div
              class="ml-2 mb-2 d-block d-md-flex"
            >
              <div class=" d-flex">
                <v-text-field
                  v-if="card.airdrop"
                  v-model="toMintId"
                  type="number"
                  light
                  filled
                  dense
                  persistent-placeholder
                  placeholder="Token Id"
                  class="ma-2 center"
                  background-color="rgb(255,255,255,0.6)"
                  :disabled="!card.mintingActive"
                />
              </div>
              <div class="d-flex">
                <v-btn
                  :disabled="!card.mintingActive"
                  class="ma-2 center"
                  color="primary"
                  @click=" toMintId ? $emit('mint',toMintId): null"
                >
                  Mint
                </v-btn>
                <v-btn
                  v-if="card.openSeaUrl"
                  class="ma-2"
                  color="secondary"
                  :href="card.openSeaUrl"
                  target="_blank"
                >
                  Opensea
                </v-btn>
              </div>
              <v-card-text
                v-text="card.notice"
              />
            </div>
          </div>
          <v-col class="ma-0 pa-0">
            <v-avatar
              class="hidden-sm-and-down"
              height="100%"
              min-width="200px"
              tile
            >
              <v-img :src="card.imageUrl" />
            </v-avatar>
          </v-col>
        </v-card>
      </v-hover>
    </v-col>
  </v-card>
</template>

<script>
  export default {
    name: 'ProjectCards',
    data: () => ({
      toMintId:null,
      cardData:{
        title:'Our Projects',
        description:'Here you can find our live, upcoming and past projects, make sure to check them all out!.',
      },
      projects: [
        {
          text: 'ArtlessLabs Geometria',
          description:'Geometria is a collection of 6,000 unique animations of geometric shapes flying around in a deterministic yet randomly generated manner. Every design and animation is completely unique and cannot be repeated.' +
              ' Deployed through Sunflower Industries\' platform.',
          imageUrl: require('@/assets/images/geometria.png'),
          mintUrl: 'https://art.sunflower.industries/project/0x566309d3523a216287bfb39c6b6bbb3a504ef5f0',
          openSeaUrl: 'https://opensea.io/collection/artlesslabs-geometria',
          mintingActive: false,
          color:'#71797E',
          notice:'Geometria has been already minted, but you can still get one in the secondary market in opensea.io'
        },
        {
          text: 'ArtlessLabs Curvy Dancers',
          description:'Curvy Dancers was developed as an appreciation project, free for Geometria Holders on a snapshot taken on october 28th 2021. This project consists of randomly generated dancing figures similar to Geometria, but unique in its own way.'
          +'Every design and animation is completely unique and cannot be repeated. Deployed on Sunflower Industries.',
          imageUrl: require('@/assets/images/curvydancers.png'),
          openSeaUrl: 'https://opensea.io/collection/curvy-dancers',
          mintingActive: true,
          airdrop: true,
          color:'#71797E',
        },
        {
          text: 'ArtlessLabs Block Friends',
          description:'Block Friends is, to our knowledge, the first people pfp project to be stored entirely in the blockchain. It will be a free to mint, one of a kind project.'
              +'Every design completely unique and cannot be repeated. Deployed on Sunflower Industries.',
          imageUrl: require('@/assets/images/pfp.png'),
          mintingActive: false,
          notice: 'Available for minting December 2021.',
          color:'#71797E',
        },
      ],
    }),
  }
</script>
<style lang="scss" scoped>
.ltitle{
  font-size: xxx-large;
  color: #181818;
  font-weight: 500;
}
.fp-cards{
  overflow: hidden;
  margin-bottom: 20px;
}
</style>