<template>
  <v-container>
    <v-layout>
      <slot />
    </v-layout>
  </v-container>
</template>

<script>
export default {
  name: "PageLayout"
}
</script>

<style scoped>

</style>