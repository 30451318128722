<template>
  <v-app>
    <NavigationBar
      app
      :items="items"
    />
    <v-main app>
      <PageLayout>
        <router-view />
      </PageLayout>
    </v-main>
    <PageFooter app />
  </v-app>
</template>

<script>

import NavigationBar from "@/components/Layout/NavigationBar";
import PageFooter from "@/components/Layout/PageFooter";
import PageLayout from "@/components/Layout/PageLayout";

export default {
  name: 'App',
  components: {PageLayout, PageFooter, NavigationBar},
  data: () => ({
    menuActive: false,
    items: [
      { title: 'Projects Homepage', to:'/' },
      { title: 'Secondary Market', href:'https://opensea.io/collection/artlesslabs-geometria' },
      { title: 'Tools', to:'/tools' },
      { title: 'Portfolio Tracker', disabled :true}
    ],
  }),
};
</script>

<style lang="scss">
  .app{
    max-width: 100%;
  }
</style>
