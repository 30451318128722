<template>
  <v-app-bar
    v-bind="$attrs"
    color="#fff"
    dark
    src="@/assets/app-bar-bg.jpeg"
    height="80px"
  >
    <template v-slot:img="{ props }">
      <v-img
        v-bind="props"
        gradient="to right, rgba(255,255,255,1), rgba(255,255,255,.9)"
      />
    </template>
    <div class="pl-sm-12 d-flex align-center">
      <v-img
        alt="Artlesslabs Logo"
        class="shrink"
        contain
        src="@/assets/logo.png"
        transition="scale-transition"
        width="200"
      />
    </div>

    <v-spacer />
    <div class="pr-sm-12">
      <v-menu
        close-on-click
        offset-y
        bottom
      >
        <template v-slot:activator="{ on, attrs }">
          <v-app-bar-nav-icon
            color="black"
            dark
            v-bind="attrs"
            v-on="on"
          />
        </template>

        <v-list>
          <v-list-item
            v-for="(item, index) in items"
            :key="index"
          >
            <v-list-item-title>
              <v-btn
                :disabled="item.disabled"
                plain
                text
                :href="item.href"
                :to="item.to"
              >
                {{ item.title }}
              </v-btn>
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </div>
  </v-app-bar>
</template>
<script>
export default {
  name: 'NavigationBar',
  props: {
    items: {
      type: Array,
      default: ()=>[],
    },
  },
}
</script>